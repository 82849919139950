body {
    font-family: 'Overpass', sans-serif;
    background-color: $grey-lt-000;
    color: $grey-dk-100;
}

h3 {
    color: $red-200;
}

h1 {
    color: #fff;
}

a {
    white-space: normal !important;
    color: $grey-dk-100 !important;
    text-decoration: none !important;
    text-decoration-color: $blue-100 !important;
}

.lead {
    margin-bottom: 2rem;
    @include fs-6;
}

#curriculum-vitae {
    display: none !important;
}

.icon:hover {
    color: $red-200 !important;
}

.site-nav {
    background-color: $grey-lt-000 !important;
}

.site-button {
        background-color: $grey-lt-000 !important;
}
.site-footer, .site-footer a {
    color: $grey-dk-100;
    background-color: $grey-lt-000;
}

.site-title {
    background-color: $grey-lt-000 !important;
    color: $red-300 !important; 
}

.site-title a {
    color: $red-300 !important;
}

.side-bar {
    border: 0 !important;
}

@media screen and (min-width:1000px) {

    .side-bar,
    .main {
        background-color: $grey-lt-000;
        padding-top: 6rem;
    }

    .site-header {
        border-bottom: 0px;
    }

    .main-header {
        border-bottom: 0px;
    }

    .main {
        padding-left: 5rem;
    }

    .main-content .anchor-heading {
        left: -2rem !important;
    }

    .side-bar {
        padding-right: 5rem;
        background-color: $grey-lt-000;
    }

    .site-title-l {
        display: none;
    }
}

@media screen and (max-width:999px) {
    .site-title-r {
        display: none;
    }
}

.site-title {
    color: $red-300 !important;
    font-weight: 500 !important;
}

.site-title:hover {
    background: none;
}

.nav-list .nav-list-item .nav-list-link:hover,
.nav-list .nav-list-item {
    background: none;
    font-weight: 300 !important;
}

.nav-list-link {
    color: $grey-dk-100;
}

.nav-list-link.active {
    font-weight: 500 !important;
    background: none !important;
    color: $grey-lt-200;
}

.nav-list .nav-list-item .nav-list-link:hover {
    text-decoration: underline;
    color: $red-300;
}

.nav-list .nav-list-item {
    @include fs-5;
    color: $grey-lt-000;
}

hr {
    background-color: $blue-300 !important;
}

.start {
    margin-bottom: 3rem;
    padding-bottom: 1rem;
}

.cv {
    margin-bottom: 3rem;
    padding-bottom: 1rem;
    border-bottom: 0px solid $blue-300;
}

.cv h1 {
    color: $red-200;
    @include fs-6;
    margin-bottom: 2rem;
}

.cv h2 {
    @include fs-5;
    color: $red-200;
    font-weight: 500;
}

.cv h3 {
    @include fs-4;
    color: $grey-dk-200;
    font-weight: 400;
}

.subtitle {
    margin-top: -.5rem;
    padding-bottom: 1rem;
    @include fs-5;
    color: $link-color;
    font-weight: 400;
}

.about {
    margin-bottom: 3rem !important;
    color: $grey-dk-100 !important;
}

.about-links {
    color: $red-300 !important;
    word-break: normal;
    font-weight: 500;
    white-space: normal !important;
    text-decoration: none;
    transition: color 0.5s ease;
}

.about-links:hover {
    color: $red-000 !important;
}

.aktuelle-projekte {
    padding-left: 0em !important;
    margin-left: 0em !important;
}

.aktuelle-projekte a {
    color: $blue-300 !important;
    white-space: normal !important;
    background-image: none !important;
    border-bottom: 1px dotted $grey-lt-200;
    transition: color 0.5s ease;
}

.aktuelle-projekte a:hover {
    color: $blue-000 !important;
    border-bottom: 1px dotted $blue-000;
    text-decoration: none !important;
}

.aktuelle-projekte li::before {
    margin-left: 0em !important;
    content: none !important;
}

.aktuelles {
    color: $grey-dk-200 !important;
    padding-left: 0em !important;
    margin-left: 0em !important;
}

.aktuelles a {
    color: $red-300 !important;
    white-space: normal !important;
    background-image: none !important;
    border-bottom: 0px dotted $grey-lt-200;
    transition: color 0.5s ease;
}

.aktuelles a:hover {
    color: $red-100 !important;
    border-bottom: 1px dotted $blue-300;
    text-decoration: none !important;
}

.aktuelles li::before {
    margin-left: 0em !important;
    content: none !important;
}

.kontext {}

.bibliography {
    /* These are technically the same, but use both */
    overflow-wrap: break-word;
    word-wrap: break-word;

    -ms-word-break: break-all;
    /* This is the dangerous one in WebKit, as it breaks things wherever */
    word-break: break-all;
    /* Instead use this non-standard one: */
    word-break: break-word;

    /* Adds a hyphen where the word breaks, if supported (No Blink) */
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
}

.fa-li {
    margin-left: -0.5em !important;
    padding-right: 0em !important;
}